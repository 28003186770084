<template>
  <div>
    <v-container grid-list-xs>
      <v-row>
        <v-col>
          <span class="text-h4"> 总动议数：{{ motionNumber }} </span>
        </v-col>
      </v-row>
      <v-row> </v-row>

      <v-row>
        <v-col>
          <bar-chart
            v-if="motionComplete"
            :chartdata="motionData"
            :options="motionOptions"
          />
        </v-col>
        <v-col>
          <bar-chart
            v-if="motionComplete"
            :chartdata="motionTypeData"
            :options="motionTypeOptions"
          />
        </v-col>
        <v-col>
          <bar-chart
            v-if="motionComplete"
            :chartdata="motionCountryData"
            :options="motionOptions"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BarChart from "./charts/bar";

export default {
  name: "LineChartContainer",
  components: { BarChart },
  data() {
    return {
      motionComplete: false,
      motionData: {},
      motionTypeData: {},
      motionCountryData: {},
      motionTypeOptions: {
        legend: { display: false },
        title: {
          display: true,
          text: "动议类型",
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                // show integer ticks
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
      motionOptions: {
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                // show integer ticks
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    motionNumber() {
      return this.$store.state.currentSession.motionHistory.length;
    },
  },
  methods: {
    computeMotionStatus() {
      const motionHistory = this.$store.state.currentSession.motionHistory;
      var mod = motionHistory.filter((x) => x.type === "mod").length;
      var unmod = motionHistory.filter((x) => x.type === "unmod").length;
      var custom = motionHistory.filter((x) => x.type === "custom").length;
      this.motionTypeData = {
        labels: ["MOD", "UNMOD", "Custom"],
        datasets: [
          {
            backgroundColor: "#71C77A",
            data: [mod, unmod, custom],
          },
        ],
      };
    },
    computeMotionCountryies() {
      const countries = this.$store.state.currentSession.inSessionCountries;
      const motionHistory = this.$store.state.currentSession.motionHistory;
      var countryLabels = [];
      var countryData = [];
      countries.forEach((country) => {
        countryLabels.push(country.name_zh);
        countryData.push(
          motionHistory.filter((x) => x.country.code === country.code).length
        );
      });

      this.motionCountryData = {
        labels: countryLabels,
        datasets: [
          {
            backgroundColor: "#71C77A",
            data: countryData,
          },
        ],
      };
    },
    computeMotionType() {
      const motionHistory = this.$store.state.currentSession.motionHistory;
      var motionPassed = motionHistory.filter((x) => x.status === "pass");
      var motionFailed = motionHistory.filter((x) => x.status === "fail");
      const pl = motionPassed.length;
      const fl = motionFailed.length;
      this.motionData = {
        labels: ["通过", "未通过"],
        datasets: [
          {
            backgroundColor: ["#43A047", "#EF5350"],
            data: [pl, fl],
          },
        ],
      };
    },
  },
  async mounted() {
    this.computeMotionStatus();
    this.computeMotionType();
    this.computeMotionCountryies();
    this.motionComplete = true;
  },
};
</script>